import React, { Component } from 'react';

import Header from './Header'
import Sidebar from './Sidebar'
import Offsidebar from './Offsidebar'
import Footer from './Footer'
//import Popups from "./Popups"

import { setupCheckSession, /*setupAutoLogoff,*/ cancelCheckSession, cancelAutoLogoff } from '../../AuthenticationUtil';
import { initialize as initializeSettingsStore, retrieveStore as retrieveSettingsStore } from '../../SettingsUtil';

class Base extends Component {
    componentWillMount() {
        // setupCheckSession(process.envs.AUTH_API);
        // setupAutoLogoff();
        initializeSettingsStore(process.envs.AUTH_API);
        retrieveSettingsStore();
    }

    componentWillUnmount() {
        // cancelCheckSession();
        // cancelAutoLogoff();
    }

    render() {
        return (
            <div className="wrapper">
                <Header />

                <Sidebar />

                <Offsidebar />

                <section className="section-container">
                    { this.props.children }
                </section>

                <Footer />
                {/* <Popups/> */}
            </div>
        );
    }
}

export default Base;
